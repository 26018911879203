header{
  background-color: #2e2e2e;
  position: sticky;
  z-index: 100;
  top: 0;

  .logo{
    width: 120px;
  }

  .container{
    display: flex;
    justify-content: space-between;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

.container{
  width: 90%;
  max-width: 1400px;
  margin: 0px auto;
  padding-top: 2rem;
  padding-bottom: 1rem;
}

.timestamp-tag{
  margin-left: 0.4rem;
  font-size: 0.8rem;
  font-weight: 300;
  color: rgba(0,0,0,0.4);
}


.auth-container{
  width: 600px;
  margin: 0px auto;
  padding: 2rem;
}

.my-4{
  margin: 2em 0;
}

.grid-5 {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 24px;
  }


  body{
    background: #F9FAFB !important;
  }
@primary-color: #1DA57A;